@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-gray-100;
}

@layer components {
  .btn-primary {
    @apply px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-blue-500 to-purple-600 rounded-full hover:from-blue-600 hover:to-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 shadow-lg transform transition duration-300 hover:scale-105;
  }
  .btn-secondary {
    @apply px-4 py-2 text-sm font-medium text-gray-700 bg-gray-200 rounded-full hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 shadow-lg transform transition duration-300 hover:scale-105;
  }
  .btn-danger {
    @apply px-4 py-2 text-sm font-medium text-white bg-gradient-to-r from-red-500 to-pink-600 rounded-full hover:from-red-600 hover:to-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 shadow-lg transform transition duration-300 hover:scale-105;
  }
}

/* Add these new styles for dropdown animations */
.dropdown-enter {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out, opacity 0.2s ease-out;
}

.dropdown-enter-active {
  max-height: 1000px; /* Adjust this value based on your content */
  opacity: 1;
}

.dropdown-exit {
  max-height: 1000px; /* Should match the value above */
  opacity: 1;
  overflow: hidden;
  transition: max-height 0.3s ease-in, opacity 0.2s ease-in;
}

.dropdown-exit-active {
  max-height: 0;
  opacity: 0;
}

/* Modal animations */
.modal-overlay {
  animation: fadeIn 0.3s ease-out;
}

.modal-content {
  animation: slideUp 0.3s ease-out;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
              0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.5; }
}

.modal-header,
.modal-footer {
  padding: 1rem;
  border-bottom: 1px solid #eaeaea;
}

.modal-footer {
  border-top: none;
  border-bottom: none;
  display: flex;
  justify-content: flex-end;
}

.modal-body {
  padding: 1rem;
}

@media (max-width: 640px) {
  .modal-content {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  .modal-content {
    border-radius: 1rem 1rem 0 0;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes gradientFlow {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes smoothReveal {
  0% {
    opacity: 0;
    transform: translateY(10px);
    filter: blur(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
    filter: blur(0);
  }
}

@keyframes smoothGradient {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

@keyframes tableReveal {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes editModalSlideUp {
  from {
    transform: translateY(30px);
    opacity: 0;
  }
  to {
    transform: translateY(-32px);
    opacity: 1;
  }
}

@layer utilities {

  .pt-safe {
    padding-top: env(safe-area-inset-top);
  }
  
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
  
  .pl-safe {
    padding-left: env(safe-area-inset-left);
  }
  
  .pr-safe {
    padding-right: env(safe-area-inset-right);
  }

  .mask-gradient {
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
}

/* For Webkit browsers (Chrome, Safari) */
.dark ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.dark ::-webkit-scrollbar-track {
  background: transparent;
}

.dark ::-webkit-scrollbar-thumb {
  background-color: rgb(75, 85, 99);
  border-radius: 4px;
}

.dark ::-webkit-scrollbar-thumb:hover {
  background-color: rgb(107, 114, 128);
}

/* For Firefox */
.dark * {
  scrollbar-width: thin;
  scrollbar-color: rgb(75, 85, 99) transparent;
}

@keyframes bob {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(10px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
